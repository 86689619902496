<template>
  <div class="col-10">
    <div class="row">
      <div class="col-12 mb-2">
        <Button color="green_light" width="100" :btnText="btnText.cart" icon="arrow" @buttonClicked="setActive('useCart')">
          <IconArrowForward color="white" />
        </Button>
      </div>
      <div class="col-12 mb-2">
        <Button color="green_light" width="100" :btnText="btnText.new" icon="arrow" @buttonClicked="setActive('SetupNew')" >
          <IconArrowForward color="white" />
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Recurring Payment ItemsInCart Footer',
  props: ['activeComponent', 'usingCart'],
  emits: ['update:activeComponent', 'update:usingCart', 'continueCart'],
  data () {
    return {
      isLogin: false
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    btnText () {
      const obj = {
        new: 'Start New Quick Recurring Donation',
        cart: 'Continue with Donations in my Cart'
      }
      if (this.isMobile) {
        obj.new = 'Start New Donation'
        obj.cart = 'Continue with my Cart'
      }
      return obj
    },
    activeComponentValue: {
      get () {
        return this.activeComponent
      },
      set (val) {
        this.$emit('update:activeComponent', val)
      }
    },
    usingCartValue: {
      get () {
        return this.usingCart
      },
      set (val) {
        this.$emit('update:usingCart', val)
      }
    }
  },
  methods: {
    setActive (val) {
      if (val === 'useCart') {
        this.usingCartValue = true
        this.$emit('continueCart')
        this.activeComponentValue = 'Frequency'
      } else {
        this.usingCartValue = false
        this.activeComponentValue = val
      }
    }
  }
}

</script>
